body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.googleSignInButton {
  background-color: #4285f4;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.googleSignInButton img {
  width: 20px;
  margin-right: 10px;
}

.fab-wrapper {
  /* Example height for the wrapper, adjust accordingly */
  height: 100px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.custom-fab {
  height: 75%;  /* 75% of the fab-wrapper's height */
  width: 75%;  /* To ensure it remains round, assuming square aspect ratio */
  min-width: auto;  /* Overriding Material-UI's default min-width for FABs */
}
