.App {
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #335C67;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.googleSignInButton {
  background-color: #4285f4;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.googleSignInButton img {
  width: 20px;
  margin-right: 10px;
}

.upperBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #333;
  color: white;
  position: sticky;
  top: 0;
  z-index: 1000;
  flex: 1; /* Allow the div to grow and take up available space */
  max-height: 10%;
}

.container {
  display: flex;
  flex-direction: column; /* Arrange items vertically */
  height: 100vh; /* Make the container take up 100% of the viewport height */
}
